/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ExamineList',
  components: {},
  props: {},
  data: function data() {
    return {
      examineList: [{
        personName: '张三',
        time: '2021-08-19 08:30',
        personid: '00011',
        state: '待审核'
      }, {
        personName: '李四',
        time: '2021-08-24 09:45',
        personid: '00012',
        state: '待审核'
      }]
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    inductionDetails: function inductionDetails() {
      this.$router.push({
        path: '/InductionDetails' // query: {
        //     personid: item.personid,
        // },

      });
    }
  }
};